import React from "react";
import './Home.css';
import {Parallax} from "react-parallax";
import Nav from '../components/Nav';
import AppSwiper from "../components/AppSwiper";
import SectionOne from "../components/SectionOne";
import SectionAboutUs from "../components/SectionAboutUs";
import SectionProductions from "../components/SectionProductions";
import { post } from '../utils/request';
import { connect } from 'redux-zero/react';
import actions from '../store/actions';
import { store } from 'react-notifications-component';
import SectionCase from "../components/SectionCase";
import SectionContact from "../components/SectionContact";


const styles = {
  fontFamily: "sans-serif",
  textAlign: "center",
  // overflowY: 'scroll',
  // scrollSnapType: 'y mandatory',
};


class Home extends React.Component {

  componentDidMount() {
    const { setLoginUser, clearLoginUser } = this.props;
    post('/users/info').then(resp => {
      const { errno, errmsg, data } = resp.data;
      if (errno !== 0) {
        console.error('[User] Query UserInfo Fail: ' + errmsg);
        return;
      }
      const { name } = data || {};
      setLoginUser(name);
    }, err => {
      clearLoginUser();
    })
  }

  render() {
    const { floatNav } = this.props;
    return (
      <div style={styles}>
        {
          floatNav && (
            <div className="float-nav">
              <Nav/>
            </div>
          )
        }
        <Parallax bgImage={"./images/bg-apps.png"} strength={100}>
          <section id="apps" className="parall-bg">
            <div className="swipe-container">
              <AppSwiper/>
            </div>
          </section>
        </Parallax>
        {/* Page 2 */}
        <SectionContact />
      </div>
    );
  }
}

const mapToProps = ({ user }) => ({ user });
export default connect(mapToProps, actions)(Home);
