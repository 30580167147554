import Home from "./pages/Home";
import User from './pages/User';

export const routes = [
  {
    path: '/login',
    component: User,
    meta: {
      showLogin: true,
    }
  },
  {
    path: '/register',
    component: User,
    meta: { }
  },
  {
    path: '/',
    component: Home,
  },
];
