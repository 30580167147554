import React from 'react';
import './SectionContact.css';
import {currentCompany} from "../utils/consts";

export default class SectionContact extends React.Component {

  render() {
    const companyName = currentCompany ? currentCompany.name : '上海好连网络科技有限公司';
    const email = currentCompany ? currentCompany.email : 'haolianggsafe@gmail.com';
    const address = currentCompany ? currentCompany.address : '上海市徐汇区钦州路201号29室';
    const icp = currentCompany ? currentCompany.icp : '沪ICP备20021080号-2';
    const beianText = currentCompany ? currentCompany.beianText : '沪公网安备';
    const beianCode = currentCompany ? currentCompany.beianCode : '31011702007533';
    const year = new Date().getFullYear()
    return (
      <section id="contact" className="section-contact-container">
        <div className="section-contact-tail">
          <div>©{year} {companyName} 版权所有</div>
          {
            icp && <a href="https://beian.miit.gov.cn" target="_blank">{icp}</a>
          }
          {
            beianCode && (
              <a href={"https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=" + beianCode} target="_blank">
                <img src="./images/icon_gongan.png" />
                <span>{`${beianText} ${beianCode}号`}</span>
              </a>
            )
          }
        </div>
      </section>
    )
  }

}
