import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter as Router, Route, Switch,} from 'react-router-dom';
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import { Provider } from 'redux-zero/react';
import { routes } from './routes';
import store from './store';
import * as consts from "./utils/consts";


function init() {
  const host = window.location.host;
  const companyMap = window.companyMap || {};
  for (const k in companyMap) {
    if (host.includes(k)) {
      consts.setCurrentCompany(companyMap[k]);
      break;
    }
  }
}

init();

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <ReactNotification />
      <Switch>
        {
          routes.map((route, i) => (
            <Route
              key={i}
              path={route.path}
              render={props => (<route.component {...props} meta={route.meta || {}} />)}
            ></Route>
          ))
        }
      </Switch>
    </Router>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
