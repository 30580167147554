import React from 'react';
import './SectionCase.css';


export default class SectionCase extends React.Component {

  render() {
    return (
      <section id="case" className="section-case-container">
        <div className="section-case-tag">服务案例</div>
        <div className="section-case-title">我们为企业提供多种服务, 并且拥有成熟案例</div>
        <img src="./images/case-1.png" />
        <img src="./images/case-2.png" />
        <img src="./images/case-3.png" />
      </section>
    )
  }
}