import React from 'react';
import './SectionAboutUs.css';
import {currentCompany} from "../utils/consts";

export default class SectionAboutUs extends React.Component {

  render() {
    const companyName = currentCompany ? currentCompany.name : '上海好连网络科技有限公司';
    const city = currentCompany ? currentCompany.city : '上海';
    return(
      <section id="about-us" className="section-us-container">
        <div className="section-us-tag">关于我们</div>
        <div className="section-us-title">我们是一家综合性移动应用服务开发商</div>
        <div className="section-us-subtitle">{`${companyName}总部位于${city}，利用积累的十余年的软件研发技术、市场营销经验和强大的数据处理能力，帮助客户创造更大的价值`}</div>
        <img src="./images/us.jpg" />
      </section>
    )
  }

}
