import React from 'react';
import './SectionOne.css';


export default class SectionOne extends React.Component {

  render() {
    return (
      <section id="home" className="page-1">
        <div className="grid-overlay">
          <div></div>
        </div>
        <div className="home-content __web-inspector-hide-shortcut__">
          <div className="row home-content__main">
            <h2>用技术改变世界</h2>
            <h3>致力于为全球用户提供移动应用服务</h3>
            <div className="home-content__button">
              <a href="#about-us" className="btn btn--primary btn--large">更多信息</a>
              <a href="#contact" className="btn btn--large">联系我们</a>
            </div>
          </div>
          <div className="home-content__scroll">
            <a href="#about" className="scroll-link">
              向上滑动
            </a>
          </div>
        </div>
      </section>
    )
  }

}