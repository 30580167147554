import axios from 'axios';

// const BASE_URL = 'http://192.168.132.122:3800';
const BASE_URL = 'http://hlwl.vip/api';

const instance = axios.create({
  baseURL: BASE_URL,
  timeout: 15000,
  withCredentials: true,
});

export const get = (path, params) => {
  return instance.get(path, {
    params,
  })
}

export const post = (path, data) => {
  return instance.post(path, data);
}
