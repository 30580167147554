import React from 'react';
import './AppSwiper.css';
import {Swiper, SwiperSlide} from 'swiper/react';
import SwiperCore, {A11y, EffectCoverflow, Pagination} from 'swiper';
import {RiStarFill} from 'react-icons/ri'
import PayQrCode from "./PayQrCode";
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import Nav from "./Nav";
import {connect} from "redux-zero/react";
import actions from "../store/actions";
import {store} from "react-notifications-component";

SwiperCore.use([EffectCoverflow, Pagination, A11y]);

const params = {
  loop: false,
  effect: 'coverflow',
  grabCursor: true,
  centeredSlides: true,
  slidesPerView: "auto",
  initialSlide: 2,
  coverflowEffect: {
    rotate: 0,
    stretch: 20,
    depth: 50,
    modifier: 2,
    // slideShadows: true
  },
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
  },
  simulateTouch: true,
  slideToClickedSlide: true,
}

const DEFAULT_CARDS = [
  {
    appId: 'voice',
    name: '录音转文字',
    icon: './images/icon_voice.png',
    thumb: './images/thumb_voice.png',
    subtitle: '支持实时录音和录音转文字准确迅速，操作简单',
    stars: 5,
    btnType: 'buy',
    headerColor: '#EEE9FA',
    downloadUrl: 'https://apps.apple.com/cn/app/id1521439409',
  },
  {
    appId: "360",
    name: '360安全扫描仪',
    icon: './images/icon_360.png',
    thumb: './images/thumb_360.png',
    subtitle: '您身边的安全扫描设备专家4小时360度贴心服务',
    stars: 5,
    btnType: 'buy',
    headerColor: '#E2FCE6',
    downloadUrl: 'https://apps.apple.com/cn/app/id1532693604',
  },
  {
    appId: "scanner",
    name: '智能扫描仪',
    icon: './images/icon_scanner.png',
    thumb: './images/thumb_scanner.png',
    subtitle: '智能扫描仪是一个PDF文档扫描应用程序将手机变成便携式扫描仪',
    stars: 5,
    btnType: 'buy',  // download / buy
    headerColor: '#E7ECFB',
    downloadUrl: 'https://apps.apple.com/cn/app/id1505921031',
  },
  {
    appId: "livephoto",
    name: '动态壁纸',
    icon: './images/icon_livephoto.png',
    thumb: './images/thumb_livephoto.png',
    subtitle: '最新最快最高清时尚的动态壁纸只需点击一下就可以下载',
    stars: 5,
    btnType: 'buy',
    headerColor: '#E7F1FB',
    downloadUrl: 'https://apps.apple.com/cn/app/id1500242104',
  },
  {
    appId: "cam",
    name: '时光相机',
    icon: './images/icon_cam.png',
    thumb: './images/thumb_cam.png',
    subtitle: '既可以让你看见未来和过去并深入了解内心的自己',
    stars: 5,
    btnType: 'buy',
    headerColor: '#FDEEF3',
    downloadUrl: 'https://apps.apple.com/cn/app/id1473916035',
  },
  {
    appId: "clean",
    name: 'PhoneMaster',
    icon: './images/icon_cleaner.png',
    thumb: './images/thumb_cleaner.png',
    subtitle: 'PhoneMaster 是一款好用的垃圾清理大师手机工具，可释放存储空间提高性能，使您的手机运行更快。',
    stars: 5,
    btnType: 'buy',
    headerColor: '#FDEEF3',
    downloadUrl: 'https://apps.apple.com/cn/app/id1473916035',
  },
];

class AppSwiper extends React.Component {

  state = {
    showQr: false,
    selectedApp: null,
    apps: [],
  }

  componentDidMount() {
    let cards = window.APPS;
    if (!cards || !cards.length) {
      cards = DEFAULT_CARDS;
    }
    this.setState({
      apps: cards,
    })
  }

  onDownloadClick() {

  }

  onBuyClick(app) {
    const { user } = this.props;
    const { hasLogin } = user || {};
    if (!hasLogin) {
      store.addNotification({
        title: '请先登录',
        message: " ",
        type: "warning",
        container: "top-center",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {duration: 2500, onScreen: true}
      });
      return;
    }
    this.setState({
      showQr: true,
      selectedApp: app,
    });
  }

  onSlideChange(swiper) {
    const index = swiper.activeIndex;
    const { apps=[] } = this.state;
    const selectedApp = apps[index];
    if (selectedApp) {
      this.setState({
        selectedApp,
      });
    }
  }

  onCard

  render() {
    const {floatNav} = this.props;
    const {
      showQr,
      selectedApp,
      apps,
    } = this.state;
    return (
      <div>
        {
          !floatNav && (
            <div className="fixed-nav">
              <Nav/>
            </div>
          )
        }
        <Swiper
          {...params}
          className="swiper-apps"
          onSlideChange={swiper => this.onSlideChange(swiper)}
        >
          {
            apps.map(app => (
              <SwiperSlide
                key={app.name}
              >
                <div className="swiper-card">
                  <div className="card-thumb" style={{backgroundImage: `url(${app.thumb})`}}>
                  </div>
                  <div className="card-content">
                    <div className="card-title"><span>{app.name}</span></div>
                    <div className="card-subtitle">{app.subtitle}</div>
                    <div className="card-stars">
                      {
                        new Array(app.stars || 5).fill(1).map((v, i) => <RiStarFill key={i} className="card-star"
                                                                                    size={22} color="#F5A623"/>)
                      }
                    </div>
                    {
                      this.renderButton(app)
                    }
                  </div>
                  {/* ICON */}
                  <div className="card-app-icon">
                    <img src={app.icon}/>
                  </div>
                </div>
              </SwiperSlide>
            ))
          }

        </Swiper>
        {
          showQr && (
            <PayQrCode
              app={selectedApp}
              onClose={() => this.setState({showQr: false})}
            />
          )
        }
        <div className="swiper-pagination"></div>
      </div>
    )
  }

  renderButton(app) {
    const showDownload = app.btnType === 'download';
    if (showDownload) {
      return (
        <div className="card-button" onClick={() => this.onDownloadClick(app)}>
          <div className="card-button-icon">
            <img src="./images/icon_apple.png"/>
          </div>
          <div>
            {/*<span>苹果应用</span>*/}
            <span>点击下载</span>
          </div>
        </div>
      )
    }
    /* Buy */
    return (
      <div className="card-button" onClick={() => this.onBuyClick(app)}>
        <div className="card-button-icon">
          <img src="./images/icon_vip.png"/>
        </div>
        <div>
          {/*<span>购买高级</span>*/}
          <span>立即购买</span>
        </div>
      </div>
    )
  }

}

const mapToProps = ({user, floatNav}) => ({user, floatNav});
export default connect(mapToProps, actions)(AppSwiper);
