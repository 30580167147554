import React from 'react';
import './Nav.css';
import {connect} from "redux-zero/react";
import actions from "../store/actions";
import { withRouter } from 'react-router-dom';
import { post } from '../utils/request';
import {store} from "react-notifications-component";
import {currentCompany} from "../utils/consts";

class Nav extends React.Component {

  onLoginClick() {
    // console.log(this.props);
    this.props.history.push('/login')
  }

  onRegister() {
    this.props.history.push('/register')
  }

  onLogout() {
    const { clearLoginUser } = this.props;
    post('/users/logout').then(resp => {
      const { errno, errmsg } = resp.data;
      if (errno === 0) {
        store.addNotification({
          title: '已退出登录',
          message: " ",
          type: "success",
          container: "top-center",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: { duration: 1500, onScreen: false }
        });
        clearLoginUser()
      }
    }, err => {
      clearLoginUser()
    })
  }

  render() {
    const { user } = this.props;
    const { name, hasLogin } = user || {};
    return (
      <div className="header">
        <div className="logo">
          {
            !currentCompany && <img src="./images/logo.png"/>
          }
          {
            currentCompany && <p className="name">{currentCompany.shortName}</p>
          }
        </div>
        {
          hasLogin && (
            <div className="header-login-user">
              <span>登录用户: </span>
              <span>{ name }</span>
              <span className="header-user-logout" onClick={() => this.onLogout()}>退出</span>
            </div>
          )
        }
        {
          !hasLogin && (
            <div>
              <button className="round-btn" onClick={() => this.onLoginClick()}>登录</button>
              <button className="round-btn" onClick={() => this.onRegister()}>注册</button>
              {/*<div>menu</div>*/}
            </div>
          )
        }
      </div>
    )
  }

}

const mapToProps = ({ user }) => ({ user });
export default connect(mapToProps, actions)(withRouter(Nav));
