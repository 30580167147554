import React from 'react';
import './SectionProductions.css';
import { RiPieChartLine, RiStarLine, RiShape2Line, RiInputMethodLine } from 'react-icons/ri'

const businessList = [
  {
    title: '软件开发',
    content: '对软件先进行需求分析，设计软件的功能和实现的算法和方法、软件的总体结构设计和模块设计、编码和调试、程序联调和测试以及编写、提交程序等一系列操作以满足客户的需求。',
    icon: <RiStarLine color="#793EA4" size={52} />,
  },
  {
    title: '技术咨询',
    content: '我们兼具行业数字化转型的需求洞察与实践能力，提供满足客户核心需求的服务产品与解决方案，加速企业数字化转型，是客户业务创新、数字化转型最可信赖的合作伙伴。',
    icon: <RiPieChartLine color="#793EA4" size={52} />,
  },
  {
    title: '网络安全',
    content: '主要包括APP加固保护、桌面软件保护、软件后门检测，通过对APP进行安全加固，保护app的数据、算法不被逆向泄露；通过对桌面收费软件加密，达到防盗版、防破解目的；检测软件是否存在后门，防止数据泄露、计算机被远程控制等。',
    icon: <RiShape2Line color="#793EA4" size={52} />,
  },
  {
    title: '品牌运营',
    content: '从影响力和影响人群两个角度入手，挖掘超级IP背后的商业价值，以数据激活为运营基础,为企业提供数字营销、新媒体运营， 提升企业的市场效率和效能。',
    icon: <RiInputMethodLine color="#793EA4" size={52} />,
  },

]

export default class SectionProductions extends React.Component {

  render() {
    return (
      <section id="products" className="section-product-container">
        <div className="section-product-tag">公司业务</div>
        <div className="section-product-title">我们主要做移动应用开发和咨询服务</div>
        <div className="business-box">
          {
            businessList.map((item, i) => (
              <div className="business-item" key={i}>
                <div className="business-item-icon">
                  {
                    item.icon
                  }
                </div>
                <div>
                  <div className="business-item-title">{item.title}</div>
                  <div className="business-item-content">{item.content}</div>
                </div>
              </div>
            ))
          }

        </div>
      </section>
    )
  }

}