
import createStore from "redux-zero";
import { PAY_STATUS } from '../utils/consts';

const initialState = {
  user: {
    name: '',
    hasLogin: false,
  },
  floatNav: false,
  payStatus: PAY_STATUS.LOADING,
};
const index = createStore(initialState)

export default index;
