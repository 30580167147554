import React from 'react';
import './PayQrCode.css';
import {connect} from "redux-zero/react";
import actions from "../store/actions";
import {PAY_STATUS} from '../utils/consts';
import {post} from '../utils/request';
import {store} from 'react-notifications-component';


class PayQrCode extends React.Component {

  state = {
    qrUrl: './images/qr.png',
    tipMsg: '支付失败!',
    price: 0,
  }

  timer = null;

  componentDidMount() {
    this.loadQrCode()
    // const { setPayStatus} = this.props;
    // setPayStatus(PAY_STATUS.SUCCESS);
  }

  componentWillUnmount() {
    // console.log("[PayQrCode] Unmounted");
    if (this.timer !== null) {
      clearInterval(this.timer);
      this.timer = null;
    }
  }

  onDownloadClick() {
    const { app } = this.props;
    const downloadUrl = app.downloadUrl || '';
    // console.log('跳转AppStore: ' + downloadUrl);
    window.open(downloadUrl,'_blank');
  }

  loadQrCode() {
    const {app, setPayStatus} = this.props;
    setPayStatus(PAY_STATUS.LOADING);
    post('/orders/create', {appId: app.appId}).then(resp => {
      const {errno, _, data} = resp.data || {};
      if (errno !== 0) {
        store.addNotification({
          title: '二维码加载失败',
          message: " ",
          type: "warning",
          container: "top-center",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {duration: 1500, onScreen: false}
        });
        this.setState({tipMsg: '二维码加载失败'});
        setPayStatus(PAY_STATUS.FAILED);
        return;
      }
      const { url, orderId, price } = data || {};
      this.setState({
        qrUrl: url,
        price,
      });
      setPayStatus(PAY_STATUS.LOADED);
      this.timer = setInterval(() => {
        this.checkOrder(orderId)
      }, 5000);
    }, err => {
      store.addNotification({
        title: '二维码加载失败',
        message: "网络请求失败, 请刷新二维码",
        type: "warning",
        container: "top-center",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {duration: 1500, onScreen: false}
      });
      this.setState({tipMsg: '二维码加载失败'});
      setPayStatus(PAY_STATUS.FAILED);
    });
  }

  checkOrder(orderId) {
    const self = this;
    const { setPayStatus} = this.props;
    post('/orders/check', { orderId }).then(resp => {
      const { errno, errmsg, data } = resp.data || {}
      if (errno !== 0) {
        console.warn("[CheckOrder] Query Order status fail: " + errmsg);
        return;
      }
      const { status } = data || {};
      if (status === 'success') {
        setPayStatus(PAY_STATUS.SUCCESS);
        self.setState({tipMsg: '支付成功!'});
        clearInterval(self.timer);
        self.timer = null;
      } else if (status === 'fail') {
        setPayStatus(PAY_STATUS.FAILED);
        self.setState({tipMsg: '支付失败'});
        clearInterval(self.timer);
        this.timer = null;
      }
      // console.log('aaaa', self)
    }, err => {

    });
  }

  onCloseClick() {
    const {onClose} = this.props;
    if (onClose) {
      onClose.call()
    }
  }

  render() {
    const {app, payStatus} = this.props;
    const {name, icon} = app || {};
    const {qrUrl, tipMsg, price} = this.state;
    return (
      <div className="qr-container">
        <div className="qr-dialog">
          <div
            className="qr-dialog-header"
            style={{ backgroundColor: app.headerColor || '#E7F1FB'}}
          >
            <img src={icon}/>
            <div>{name}</div>
          </div>
          <div className="qr-dialog-content">
            <div className="qr-dialog-content-pay">
              {
                (payStatus === PAY_STATUS.SUCCESS || payStatus === PAY_STATUS.FAILED) && (
                  <div>
                    <img
                      className="qr-dislog-content-icon"
                      src={payStatus === PAY_STATUS.SUCCESS ? "./images/icon_pay_success.png" : "./images/icon_pay_fail.png"}
                    />
                    <div className="qr-dialog-fail-msg">
                      <span>{tipMsg}</span>
                      {
                        payStatus === PAY_STATUS.FAILED && (
                          <span className="qr-dialog-refresh" onClick={() => this.loadQrCode()}>点击刷新</span>
                        )
                      }
                    </div>
                    {
                      payStatus === PAY_STATUS.SUCCESS && (
                        <div className="qr-dialog-button" onClick={() => this.onDownloadClick(app)}>
                          <div className="qr-dialog-button-icon">
                            <img src="./images/icon_apple.png"/>
                          </div>
                          <div>
                            {/*<span>苹果应用</span>*/}
                            <span>点击下载</span>
                          </div>
                        </div>
                      )
                    }
                  </div>
                )
              }
              {
                (payStatus === PAY_STATUS.LOADING || payStatus === PAY_STATUS.LOADED) && (
                  <React.Fragment>
                    {
                      price > 0 && (<div>{`支付${price}元`}</div>)
                    }
                    <img className="qrcode" src={payStatus === PAY_STATUS.LOADING ? './images/loading.gif' : qrUrl} />
                    <div className="qr-dialog-content-tip">
                      <span>用微信扫描二维码</span>
                      <span>开始支付</span>
                    </div>
                  </React.Fragment>
                )
              }
            </div>
          </div>
        </div>
        <div className="qr-dialog-close">
          <img src='./images/icon_close.png' onClick={() => this.onCloseClick()}/>
        </div>
      </div>
    )
  }

}


const mapToProps = ({user, payStatus}) => ({user, payStatus});
export default connect(mapToProps, actions)(PayQrCode);
