import React from 'react';
import './User.css';
import { post } from '../utils/request';
import {connect} from "redux-zero/react";
import actions from "../store/actions";
import { store } from 'react-notifications-component';
import {currentCompany} from "../utils/consts";

class UserPage extends React.Component {

  state = {
    account: '',
    passwd: '',
    policyAccepted: false,
  }

  componentDidMount() {

  }

  onSubmit() {
    const { meta, setLoginUser } = this.props;
    const { account, passwd, policyAccepted } = this.state;
    if (!policyAccepted) {
      store.addNotification({
        title: ' ',
        message: '请先勾选同意隐私协议选项',
        type: "warning",
        container: 'top-center',
        dismiss: { duration: 2000 },
      });
      return;
    }
    if (!account || !passwd) {
      store.addNotification({
        title: ' ',
        message: '请填写账号密码',
        type: "warning",
        container: 'top-center',
        dismiss: { duration: 2000 },
      })
      console.error('Empty Fields..');
      return;
    }
    // TODO: CheckBox
    const { showLogin } = meta || {};
    const path = showLogin ? '/users/login' : '/users/register';
    post(path, {
      account,
      passwd,
    }).then(resp => {
      // console.log(resp);
      const { errno, errmsg, data } = resp.data;
      if (errno !== 0) {
        console.error('Login Fail: ' + errmsg);
        store.addNotification({
          title: showLogin ? '登录失败': '注册失败',
          message: errmsg,
          type: "warning",
          container: 'top-center',
          dismiss: { duration: 2000 },
        })
        return;
      }
      const { name } = data || {};
      // console.log('Login Success: ' + name);
      setLoginUser(name, true);
      store.addNotification({
        title: showLogin ? '登录成功': '注册成功',
        message: "正在跳转页面...",
        type: "success",
        container: "top-center",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 1500,
          onScreen: true,
        }
      });
      setTimeout(() => {
        this.props.history.replace('/')
      }, 1500);

    }).catch(err => {
      setLoginUser('', false);
      store.addNotification({
        title: showLogin ? '登录失败': '注册失败',
        message: '网络请求出错, 请稍候再试',
        type: "warning",
        container: 'top-center',
        dismiss: { duration: 2000 },
      })
      console.error(err);
    })
  }

  onInputChange(e) {
    const target = e.target;
    this.setState({
      [target.name]: target.value || '',
    })
  }

  onCheckboxChange(e) {
    // console.log(e)
    const checked = e.target.checked
    this.setState({
      policyAccepted: checked,
    })
  }

  render() {
    const { meta } = this.props;
    const { showLogin } = meta || {};
    const shortName = currentCompany ? currentCompany.shortName : "好连";

    return (
      <div className="login-container">
        <div className="login-box">
          <div
            className="login-thumb"
            style={{backgroundImage: "url(./images/login-bg.png)"}}
          >
            {
              !currentCompany && <img src="./images/logo_haolian.png"/>
            }
            {
              currentCompany && <p className="name">{shortName}</p>
            }
          </div>
          <div className="login-form">
            <div className="login-form-header">
              <span className="login-form-header-title">{(showLogin ? '欢迎登录' : '欢迎注册') + shortName}</span>
              <span className="login-form-header-subtitle">用技术改变世界</span>
            </div>
            <div className="login-form-inputs">
              <span>手机号/邮箱</span>
              <input type="text" name="account" placeholder="请输入手机号或邮箱" onChange={(e) => this.onInputChange(e)}/>
              <span>密码</span>
              <input type="password" name="passwd" placeholder="请输入密码" onChange={(e) => this.onInputChange(e)}/>
            </div>
            <div>
              <button className="login-form-btn" onClick={() => this.onSubmit()}>{showLogin ? '登 录' : `注 册`}</button>
              <div className="login-form-checkbox-label">
                <input type="checkbox" id="policy-accept" onChange={(e) => { this.onCheckboxChange(e)}}/>
                <label htmlFor="policy-accept">我已阅读并同意注册等相关<span>隐私协议</span></label>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

}

const mapToProps = ({ user }) => ({ user });
export default connect(mapToProps, actions)(UserPage);
