
export const PAY_STATUS = {
  LOADING: 1,
  LOADED: 2,
  SUCCESS: 3,
  FAILED: 4,
};

export let currentCompany = null;

export const setCurrentCompany = (companyInfo) => {
  currentCompany = companyInfo;
};
